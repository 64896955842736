<template>
  <div class="order">
    <!-- <title-top>我的订单</title-top> -->

    <div class="h30 bg-white"></div>
    <div class="row align-center w100 h72 bg-white font32 f-1A1A1A">
      <img class="imgsize44 ml25" src="~images/fanhui.png" alt @click="onBack" />
      <span class="ml75 flex1" :class="{active:currentIndex == 0}" @click="onTab(0)">淘宝</span>
      <span class="flex1" :class="{active:currentIndex == 1}" @click="onTab(1)">京东</span>
      <span class="flex1" :class="{active:currentIndex == 2}" @click="onTab(2)">拼多多</span>
    </div>
    <div class="row just-center mt20 mb20" v-if="false">
      <div class="row align-center position_r br15 bg-white search">
        <i class="shuxian"></i>
        <input
          class="font28 f-999999 br15 bg-white position_a search-box"
          type="text"
          placeholder="查询或搜索订单编号"
        />
        <div class="column position_a flex-center searchbg">
          <img class="searchbtn" src="~images/sousuo2.png" />
        </div>
      </div>
    </div>
    <div class="bg-white h30"></div>
    <div class="row flex-around bg-white font24 f-666666 h55" style="align-items: flex-start;">
      <!-- 被选中全部 -->
      <div class="column flex-center" @click="onChildTab(0)">
        <span :class="{active:childIndex == 0}">全部</span>
        <div class="mt8" :class="{underline:childIndex == 0}"></div>
      </div>
      <div class="column flex-center" @click="onChildTab(1)">
        <span :class="{active:childIndex == 1}">待返佣</span>
        <div class="mt8" :class="{underline:childIndex == 1}"></div>
      </div>
      <div class="column flex-center" @click="onChildTab(2)">
        <span :class="{active:childIndex == 2}">已完成</span>
        <div class="mt8" :class="{underline:childIndex == 2}"></div>
      </div>
      <div class="column flex-center" @click="onChildTab(3)">
        <span :class="{active:childIndex == 3}">已结算</span>
        <div class="mt8" :class="{underline:childIndex == 3}"></div>
      </div>
    </div>
    <div class="line"></div>
    <!-- </div> -->
    <div v-for="(item,index) in list" :key="index" class="bg-white">
      <div class="row between align-center bg-white h80 font24">
        <span class="f-333333 ml25">订单编号：{{item.number}}</span>
        <span class="row flex-center state br8 f-F62341 mr25">{{item.state_zh_cn}}</span>
      </div>
      <div class="line"></div>
      <div class="row between align-center bg-white h204" v-for="(child,i) in item.items" :key="i">
        <img class="imgsize176 br4 ml25" :src="child.image_url" alt />
        <div class="flex1 column font24 f-1A1A1A ml16">
          <p class="ellipsis mt8 mr40">{{child.product_name}}</p>
          <div class="row between mt16 align-center">
            <span class="font28 fontwb">￥{{child.unit_price}}</span>
            <span class="f-F62341 mr25">预估返{{child.estimated_commission}}</span>
          </div>
        </div>
      </div>
      <div class="row flex-end mr25">
        <span class="font22 f-999999 mb20">{{item.ordered_at | formatDate}}</span>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { formatDate } from "common/util";
import TitleTop from "@/components/common/title/TitleTop";
import { tbk_orders, jingdong_orders, pinduoduo_orders } from "network/igou";

export default {
  name: "MyOrder",
  data() {
    return {
      currentIndex: this.$route.query.index ? this.$route.query.index : 0,
      childIndex: 0,
      state: "",
      list: []
    };
  },
  mounted() {
    this.initData();
  },
  filters: {
    formatDate(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
    formatHs(time) {
      var date = new Date(time);
      return formatDate(date, "hh:mm");
    },
    formatYear(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd");
    }
  },

  methods: {
    changeChild() {
      if (this.childIndex == 0) {
        this.state = "";
      } else if (this.childIndex == 1) {
        this.state = "paid";
      } else if (this.childIndex == 2) {
        this.state = "finished";
      } else if (this.childIndex == 3) {
        this.state = "settled";
      }
    },
    initData() {
      if (this.currentIndex == 0) {
        this.get_tbk_orders();
      } else if (this.currentIndex == 1) {
        this.get_jingdong_orders();
      } else if (this.currentIndex == 2) {
        this.get_pinduoduo_orders();
      }
    },
    get_tbk_orders() {
      tbk_orders(this.state).then(res => {
        console.log(res);
        this.list = res.data;
      });
    },
    get_jingdong_orders() {
      jingdong_orders(this.state).then(res => {
        console.log(res);
        this.list = res.data;
      });
    },
    get_pinduoduo_orders() {
      pinduoduo_orders(this.state).then(res => {
        console.log(res);
        this.list = res.data;
      });
    },
    onBack() {
      this.$router.back();
    },
    onTab(index) {
      if (this.currentIndex == index) return;
      this.currentIndex = index;
      this.childIndex = 0;
      this.changeChild();
      this.initData();
    },
    onChildTab(index) {
      if (this.childIndex == index) return;
      this.childIndex = index;
      this.changeChild();
      this.initData();
    }
  },
  components: {
    TitleTop
  }
};
</script>
<style  scoped>
.active {
  color: #f62341;
}
.search {
  box-sizing: border-box;
  border: 0.013333rem solid #f62341;
  width: 8.133333rem;
  height: 0.826667rem;
}
.shuxian {
  position: absolute;
  left: 0.373333rem;
  top: 0.213333rem;
  height: 0.373333rem;
  width: 0.026667rem;
  background-color: #f62341;
}
.search-box {
  box-sizing: border-box;
  left: 0.6rem;
  border: none;
  outline: none;
  cursor: pointer;
  width: 6rem;
  height: 0.746667rem;
}
.searchbg {
  right: 0;
}
.searchbtn {
  width: 1.306667rem;
  height: 0.8rem;
}
.underline {
  height: 0.026667rem;
  width: 0.64rem;
  background-color: #f62341;
}
.state {
  border: 0.013333rem solid #f62341;
  width: 1.546667rem;
  height: 0.64rem;
}
</style>
